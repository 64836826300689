export const getAPIBase = () => {
    if (process.env.REACT_APP_MODE === "multi") {
        return "TODO";
    }

    if (process.env.NODE_ENV === "production") {
        return "/api";
    }

    return "http://127.0.0.1:9080/api";
};
