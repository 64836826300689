import React from "react";
import { Link, useHistory } from "react-router-dom";

import Cow from "../images/Cow-01.svg";

const NotFound = () => {
    const history = useHistory();

    let canGoBack = false;

    if (history.length > 1) {
        canGoBack = true;
    }

    return (
        <div style={{ textAlign: "center", height: "100%" }}>
            <img
                src={Cow}
                alt="404: Page not found"
                style={{ height: "400px" }}></img>
            <br />
            <span style={{ fontSize: "2em", fontWeight: 200 }}>
                <b style={{ fontWeight: 800 }}>404</b>: Page not found.
            </span>
            <br />
            <p>
                Please check the URL for any spelling mistakes,{" "}
                {canGoBack ? (
                    <>
                        <a
                            href={"#back"}
                            onClick={(ev) => {
                                ev.preventDefault();
                                history.goBack();
                            }}>
                            go back
                        </a>
                        ,{" "}
                    </>
                ) : null}
                or return <Link to={"/"}>to the beginning</Link>.
            </p>
        </div>
    );
};

export default NotFound;
