import { configureStore } from "@reduxjs/toolkit";
import brandingReducer from "../features/branding/brandingSlice";
import counterReducer from "../features/counter/counterSlice";
import userReducer from "../features/user/userSlice";
import { loadState, saveState } from "./localStorage";

const persistedState = loadState();

const store = configureStore({
    reducer: {
        counter: counterReducer,
        user: userReducer,
        branding: brandingReducer,
    },
    preloadedState: persistedState,
});

store.subscribe(() => {
    saveState(store.getState());
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
