import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BroadcastMessage, sendMessage } from "../../app/broadcastChannel";
import type { RootState } from "../../app/store";

interface UserState {
    username: string | null;
    fullName: string | null;
    token: string | null;
}

const initialState: UserState = {
    username: null,
    fullName: null,
    token: null,
};

const logOutF = (state: UserState) => {
    state.username = null;
    state.fullName = null;
    state.token = null;
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;

            sendMessage({
                action: "setToken",
                payload: action.payload,
            });
        },
        setFullName: (state, action: PayloadAction<string>) => {
            state.fullName = action.payload;

            sendMessage({
                action: "setFullName",
                payload: action.payload,
            });
        },
        setUsername: (state, action: PayloadAction<string>) => {
            state.username = action.payload;

            sendMessage({
                action: "setUsername",
                payload: action.payload,
            });
        },
        logOut: (state) => {
            logOutF(state);

            sendMessage({
                action: "logOut",
                payload: null,
            });
        },
        receiveUserUpdate: (
            state,
            action: PayloadAction<BroadcastMessage<any>>
        ) => {
            if (action.payload.action === "logOut") {
                logOutF(state);
                return;
            }
            if (action.payload.action === "setUsername") {
                state.username = action.payload.payload;
                return;
            }
            if (action.payload.action === "setFullName") {
                state.fullName = action.payload.payload;
                return;
            }
            if (action.payload.action === "setToken") {
                state.token = action.payload.payload;
                return;
            }
        },
    },
});

export const { setToken, setFullName, setUsername, logOut, receiveUserUpdate } =
    userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
