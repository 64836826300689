import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { APIMetadata } from "../../types/api";

interface BrandingState {
    wasFetched: boolean;
    appName: string;
    colorAccent: string;
    colorBg: string;
    loginBg: string;
    loginBgBlend: string;
    demoMode: boolean;
    licenseID: string | null;
    version: string | null;
}

const initialState: BrandingState = {
    wasFetched: false,
    appName: "Minerva",
    colorAccent: "#e6152a",
    colorBg: "#130920",
    loginBg: `linear-gradient(55deg, #000850 0%, #000320 100%),
    radial-gradient(100% 225% at 100% 0%, #ff6928 0%, #000000 100%),
    linear-gradient(235deg, #bdff00 0%, #000000 100%),
    linear-gradient(
        180deg,
        #002bdc 0%,
        #002bdc 45%,
        #2f4bff 45%,
        #2f4bff 60%,
        #00a6e7 60%,
        #00a6e7 80%,
        #ffe37f 80%,
        #ffe37f 100%
    )`,
    loginBgBlend: "screen, overlay, hard-light, normal",
    demoMode: false,
    licenseID: null,
    version: null,
};

export const brandingSlice = createSlice({
    name: "branding",
    initialState,
    reducers: {
        setBranding: (state, action: PayloadAction<BrandingState>) => {
            state = action.payload;
        },
        setBrandingMeta: (state, action: PayloadAction<APIMetadata>) => {
            let payload = action.payload;
            let metaBranding = payload.branding;
            let licenseID = payload.licenseID;
            let version = payload.minervaVersion;
            let isDemo = payload.isDemo;

            state.licenseID = licenseID;
            state.version = version;

            if (metaBranding.appName) state.appName = metaBranding.appName;
            else state.appName = initialState.appName;
            if (metaBranding.colorAccent)
                state.colorAccent = metaBranding.colorAccent;
            else state.colorAccent = initialState.colorAccent;
            if (metaBranding.colorBg) state.colorBg = metaBranding.colorBg;
            else state.colorBg = initialState.colorBg;
            if (metaBranding.loginBg) state.loginBg = metaBranding.loginBg;
            else state.loginBg = initialState.loginBg;
            if (metaBranding.loginBgBlend)
                state.loginBgBlend = metaBranding.loginBgBlend;
            else state.loginBgBlend = initialState.loginBgBlend;
            state.demoMode = isDemo;

            state.wasFetched = true;
        },
    },
});

export const { setBranding, setBrandingMeta } = brandingSlice.actions;

export const selectBranding = (state: RootState) => state.branding;

export default brandingSlice.reducer;
