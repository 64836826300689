import axios from "axios";
import React, { lazy, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    RouteProps,
} from "react-router-dom";
import { getAPIBase } from "./app/apiHelper";
import Loading from "./components/Loading";
import NotFound from "./components/NotFound";
import { Helmet } from "react-helmet";
import {
    selectBranding,
    setBrandingMeta,
} from "./features/branding/brandingSlice";
import { logOut, selectUser } from "./features/user/userSlice";
import { APIMetadata } from "./types/api";
import * as Sentry from "@sentry/react";

const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard"));

const Logout = () => {
    const dispatch = useDispatch();

    dispatch(logOut());

    return <Redirect to="/"></Redirect>;
};

const AuthRoute = (props: RouteProps) => {
    const user = useSelector(selectUser);

    return user.token !== null ? (
        <Route {...props} />
    ) : (
        <Route path={props.path} exact={props.exact}>
            <Redirect to={"/login?to=" + props.path} />
        </Route>
    );
};

const App = () => {
    const user = useSelector(selectUser);
    const [invalidSession, setInvalidSession] = useState(false);
    const branding = useSelector(selectBranding);
    const dispatch = useDispatch();
    const [brandingLoaded, setBrandingLoaded] = useState(false);

    if (!brandingLoaded) {
        axios({
            url: getAPIBase() + "/metadata",
        })
            .then((res) => {
                let brandingFetched = res.data as APIMetadata;

                dispatch(setBrandingMeta(brandingFetched));
                setBrandingLoaded(true);
            })
            .catch(() => {
                setBrandingLoaded(true);
            });
    }

    useEffect(() => {
        if (user.token)
            axios(getAPIBase() + "/auth/check", {
                headers: { Authorization: "Bearer " + user.token },
            })
                .then((res) => {
                    if (!res.data) {
                        setInvalidSession(true);
                    }
                })
                .catch(() => {
                    setInvalidSession(true);
                });
    });

    if (!brandingLoaded && !branding.wasFetched) return <Loading />;

    let style = {
        "--mv-colorAccent": branding.colorAccent,
        "--mv-colorBg": branding.colorBg,
    } as React.CSSProperties;

    Sentry.setContext("branding", branding);

    return (
        <div style={style}>
            <Helmet>
                <title>{branding.appName}</title>
            </Helmet>
            <Router>
                {invalidSession && <Redirect to={"/logout"} />}
                <Switch>
                    <Route path="/" exact>
                        <Redirect
                            to={
                                user.token !== null ? "/dashboard" : "/login"
                            }></Redirect>
                    </Route>
                    <AuthRoute path="/dashboard" component={Dashboard} />
                    <Route path="/login" component={Login} />
                    <AuthRoute path="/logout" component={Logout} />

                    <Route path="/">
                        <Helmet>
                            <title>404 - {branding.appName}</title>
                        </Helmet>
                        <NotFound />
                    </Route>
                </Switch>
            </Router>
        </div>
    );
};

export default App;
