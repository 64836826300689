import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { selectBranding } from "../features/branding/brandingSlice";

import loader from "../images/loading.svg";

const Loading = () => {
    const branding = useSelector(selectBranding);
    return (
        <div style={{ textAlign: "center", height: "100%" }}>
            <Helmet>
                <title>Loading... - {branding.appName}</title>
            </Helmet>
            <img
                src={loader}
                alt="Loading..."
                style={{ height: "400px" }}></img>
            <br />
            <span style={{ fontSize: "2em", fontWeight: 200 }}>
                The page is <b style={{ fontWeight: 800 }}>loading</b>.
            </span>
            <br />
            <p>We'll be with you shortly!</p>
        </div>
    );
};

export default Loading;
