import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import Loading from "./components/Loading";
import store from "./app/store";
import { Provider } from "react-redux";
import BroadcastEmitter from "./app/broadcastChannel";
import { receiveUserUpdate } from "./features/user/userSlice";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { version } from "../package.json";

Sentry.init({
    dsn: "https://b8f0e5974e8241af97a34b308ffa47c3@o1009564.ingest.sentry.io/5973734",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.25,
    release: version,
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Suspense fallback={<Loading />}>
                <App />
            </Suspense>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

BroadcastEmitter.on("message", (msg) => {
    if (
        msg.action === "logOut" ||
        msg.action === "setToken" ||
        msg.action === "setFullName" ||
        msg.action === "setUsername"
    ) {
        store.dispatch(receiveUserUpdate(msg));
        return;
    }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
